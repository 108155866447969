import React from "react";
import styled from "styled-components";

import { Color } from "../../utils/Const";

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  color: ${Color.Black};
`;

export const Layout = ({children}) => {
  return(
    <LayoutWrapper>
      {children}
    </LayoutWrapper>
  );
}
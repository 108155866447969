import React,  { useEffect } from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import styled from "styled-components";

import { Container } from "../../components/parts/Container";
import { Button } from "../../components/parts/Button";

import { Responsive, Color } from "../../utils/Const"

import logo from "../../images/otsutome@2x.png";

const HeaderWrapper = styled.header`
  position: fixed;
  width: 100%;
  margin: 0 0;
  padding: 0.5rem 0rem;
  box-sizing: border-box;
  z-index: 1000;
  transition: 0.5s;
  &[data-active="true"] {
    background-color: ${Color.Header};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

const Img = styled.img`
  width: 128px;
  margin: 0 0;
  @media(max-width: ${Responsive.SP}px) {
    width: 96px;
    padding: 0;
  }  
`

const Nav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  a:last-child {
    margin-left: auto;
  }
`;

const title = `Web勤怠管理システム「オツトメ！」 | 完全無料で利用可能`;
const description = `勤務時間の管理コストを大幅削減！簡単、便利、安心の勤怠管理システムです！まずはシンプルな機能だけを搭載。スマホ・タブレットにも対応！メッセージ機能も標準搭載！全ページSSL対応！CSVファイル出力・取込機能！在宅勤務（リモートワーク）にも！無料にしてここまで使えるWeb勤怠管理システムは、オツトメだけ！`;

export const Header = (props) => {

  useEffect(() => {
    const headerElement = document.querySelector("#header");
    const targetElement = document.querySelector("#first-view");

    if (!headerElement) return;

    if (!targetElement) {
      return headerElement.setAttribute("data-active", true);
    }

    const observeHandler = (entries) => {
      headerElement.setAttribute("data-active", !entries[0].isIntersecting);
    };

    const headerObserver = new window.IntersectionObserver(observeHandler);
    headerObserver.observe(targetElement);
  }, []);

  const url = typeof window !== `undefined` ? window.location.href : ''

  return(
    <HeaderWrapper id={"header"}>
      <Helmet
        htmlAttributes={{
          lang: "ja",
        }}
        title={props.title ? `${props.title} | ${title}` : title}
        meta={[
          {
            name: "keywords",
            content: props.keywords || "勤怠,管理,オツトメ！,フリー,スマホ,タブレット,完全無料,Web,リモートワーク,在宅勤務",
          },
          {
            name: "description",
            content: props.description ?
              `完全無料で使える、フリーのWeb勤怠管理システム「オツトメ！」の${props.description}ページです。${description}`
              :
              `ついに登場！完全無料で使える、フリーのWeb勤怠管理システム「オツトメ！」。${description}`
          },
          {
            property: "og:locale",
            content: "ja_JP",
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property: "og:title",
            content: props.title ? `${props.title} | ${title}` : title
          },
          {
            property: "og:description",
            content: props.description ?
              `完全無料で使える、フリーのWeb勤怠管理システム「オツトメ！」の${props.description}ページです。${description}`
              :
              `ついに登場！完全無料で使える、フリーのWeb勤怠管理システム「オツトメ！」。${description}`
          },
          {
            property: "og:url",
            content: url
          },
          {
            property: "og:site_name",
            content: title
          },
          {
            property: "og:image",
            content: `https://www.otsutome.net${logo}`
          },
          {
            name: "twitter:card",
            content: "summary"
          },
          {
            name: "twitter:site",
            content: "@otsutome"
          },
          {
            name: "twitter:creator",
            content: "@otsutome"
          },
        ]}
      />
      <Container>
        <Nav>
          <Link to={`/`}>
            <Img src={logo}></Img>
          </Link>
          <Button href={`https://otsutome.net/account/entry`}>会員登録</Button>
        </Nav>
      </Container>
    </HeaderWrapper>
  )
}

import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import imgTwitter from "../images/twitter-white.png";

import { Container } from "../components/parts/Container";
import { Responsive, Text, Color, FontSize } from "../utils/Const";

const FooterWrapper = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 2rem 0 1rem;
  background-color: ${Color.Orange};
  color: ${Color.White};
`;

const FooterLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  @media(max-width: ${Responsive.SP}px) {
    flex-direction: column;
  }
`;

const FooterLink = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ul {

  }
  li {
    list-style: none;
    margin: 1.0rem 0;
    font-size: ${FontSize.sm};
  }
  a {
    text-decoration: none;
    color: ${Color.White};
    &:hover {
      text-decoration: underline;
    }
    &:active, &:visited {
      color: ${Color.White};
    }
  }
`;

const SNSWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 1.0rem 0;
  img {
    width: 24px;
  }
`;

const Copyright = styled.small`
  width: 100%;
  display: block;
  text-align: center;
  margin: 0 0 1rem;
  font-size: ${FontSize.xxs};
  @media(max-width: ${Responsive.SP}px) {
    font-size: ${FontSize.xxxs};
  }
`;


export const Footer = () => {
  return(
    <FooterWrapper>
      <Container>
        <FooterLinkWrapper>
          <FooterLink>
            <ul>
              <li><a href={`https://otsutome.net/account/entry`} target="_blank" rel="noopener noreferrer">新規会員登録</a></li>
              <li><a href={`https://demo.otsutome.net/demo/login`} target="_blank" rel="noopener noreferrer">デモ版の体験</a></li>
              <li><Link to={`/functions`}>機能一覧</Link></li>
            </ul>
          </FooterLink>
          <FooterLink>
            <ul>
              <li><Link to={`/news`}>お知らせ</Link></li>
              <li><Link to={`/questions`}>よくあるご質問</Link></li>
              <li><Link to={`/terms`}>ご利用規約</Link></li>
              <li><Link to={`/privacy`}>プライバシーポリシー</Link></li>
            </ul>
          </FooterLink>
          <FooterLink>
            <ul>
              <li><Link to={`/company`}>運営会社</Link></li>
              <li><a href="https://otsutome.net/contact" target="_blank" rel="noopener noreferrer">お問い合わせ</a></li>
            </ul>
          </FooterLink>
        </FooterLinkWrapper>
        <SNSWrapper>
          <a href="https://twitter.com/otsutome" target="_blank" rel="noopener noreferrer"><img alt="Twitter" src={imgTwitter} /></a>
        </SNSWrapper>
        <Copyright>
          {Text.SERVICE_NAME} © 2010 Nextribe Inc. all rights reserved.
        </Copyright>
      </Container>
    </FooterWrapper>
  );
}
import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Responsive, Color, FontSize } from "../../utils/Const"

const Btn = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  min-width: 150px;
  height: 30px;
  padding: 1.5rem 1rem;
  color: ${Color.White};
  font-weight: bold;
  font-size: ${FontSize.xxs};
  text-align: center;
  text-decoration: none;
  filter: drop-shadow(2px 2px 2px rgba(99, 99, 99, 0.6));
  background-color: ${Color.Orange};
  border-radius: 2rem;
  transition: 0.5s;
  &:hover {
    color: ${Color.Orange};
    background-color: ${Color.White};
    text-decoration: none;
  }
  @media(max-width: ${Responsive.SP}px) {
    min-width: 120px;
    padding: 1.2rem 1.0rem;
    font-size: ${FontSize.xxxs};
  }
`;

export const Button = ({props, children, to, href}) => {
  if (href) {
    return(
      <Btn
        href={href}
        target={`_blank`}
        rel={`noopener noreferrer`}
      >{children}</Btn>
    )
  } else {
    return(
      <Btn
        to={to}
      >{children}</Btn>
    )
  }
}

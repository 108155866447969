import React from "react";
import styled from "styled-components";

import { Responsive } from "../../utils/Const";

const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${Responsive.PC}px;
  margin: auto;
  padding: 0;
  box-sizing: border-box;
  @media(min-width: ${Responsive.SP}px) and (max-width: ${Responsive.PC}px) {
    padding 0 1.25rem;
  }  
  @media(max-width: ${Responsive.SP}px) {
    padding 0 1.25rem;
  }  
`;

export const Container = ({children}) => {
  return(
    <ContentWrapper>
      {children}
    </ContentWrapper>
  )
}
import React from "react";
import styled from "styled-components";

import { Responsive, Color } from "../../utils/Const";

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem 1rem;
  box-sizing: border-box;
  background-color: ${Color.White};
  @media(min-width: ${Responsive.SP}px) and (max-width: ${Responsive.PC}px) {
    padding: 1rem 0rem;
  }  
  @media(max-width: ${Responsive.SP}px) {
    padding: 1rem 0rem;
  }  
`;

export const WhiteWrapper = ({children}) => {
  return(
    <Wrapper>
      {children}
    </Wrapper>
  );
}
export const Text = {
  SERVICE_NAME: "オツトメ！",
};

export const Responsive = {
  PC: 1024,
  SP: 768,
};

export const Color = {
  White: "rgba(255, 255, 255, 1.0)",
  Black: "rgb(61, 69, 72)",
  Header: "rgba(253, 245, 217, 1.0)",
  Yellow: "rgba(253, 245, 217, 0.4)",
  LightYellow: "rgba(253, 245, 217, 0.2)",
  Orange: "rgba(255, 145, 77, 1.0)",
  LightOrange: "rgba(255, 145, 77, .2)",
  Border: "#ccc",
  Shadow: "rgba(253, 245, 217, 0.4)",
  Table: "#fefefe",
  Gray: "#666",
  LightGray: "#aaa",
  Pink: "#ff66c4",
};

export const FontSize = {
  xl: "4.0rem",
  lg: "2.5rem",
  md: "2.0rem",
  sm: "1.4rem",
  xs: "1.2rem",
  xxs: "1.0rem",
  xxxs: "0.8rem",
};

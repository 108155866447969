import React from "react";
import styled from "styled-components";

import { Responsive, FontSize } from "../../utils/Const";

const TitleViewWrapper = styled.h1`
  font-size: ${FontSize.lg};
  font-weight: normal;
  margin: 4rem 0 1rem;
  @media(max-width: ${Responsive.SP}px) {
    font-size: ${FontSize.md};
  }  
`

export const TitleView = ({children}) => {
  return (
    <TitleViewWrapper>
      {children}
    </TitleViewWrapper>
  );
}